import { FC, Fragment, useEffect, useState } from "react";
import SideBar from "./SideBar";
import { TopBar } from "../TopBar";
import { Transition } from "@headlessui/react";
import { HomeIcon, CreditCardIcon, UserIcon, UsersIcon, UserGroupIcon, CalendarDaysIcon } from "@heroicons/react/24/solid"
import Link from 'next/link'
import { useRouter } from "next/router"
import { MdBusiness, MdPaid } from "react-icons/md";
import { RiEarthLine } from "react-icons/ri";
import { AiOutlineFileProtect } from "react-icons/ai";
import { TbChartInfographic } from "react-icons/tb"
import { useSession } from "next-auth/react";
import RoleGuard from "@/shared/RoleGuard";
import RolesNames from "@/shared/RolesNames";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { GiLaurelsTrophy } from "react-icons/gi";


interface Props {
    title?: string;
    children: React.ReactNode;
}

export const LayoutAdmin: FC<Props> = ({ children, title }) => {
    const {data: session} = useSession()
    const router = useRouter()
    const [showNav, setShowNav] = useState(true);
    const [isMobile, setIsMobile] = useState(false);


    function handleResize () {        
        if (innerHeight <= 640) {
            // setShowNav(false)
            setIsMobile(true)
        } else {
            // setShowNav(true)
            setIsMobile(false)
        }
    }

    useEffect(() => {
        if (typeof window != undefined) {
            addEventListener("resize", handleResize)
        }

        return () => {
            removeEventListener("resize", handleResize)
        }
    }, [])

    function item(titulo: string, icon: any, link: string, pathname: string) {
        return <Link href={`${link}`}>
            <div
                className={`pl-6 py-3 mx-5 rounded text-center cursor-pointer mb-3 flex items-center transition-colors ${router.pathname == pathname
                    ? "bg-red-100 text-red-500"
                    : "text-gray-400 hover:bg-orange-100 hover:text-red-500"
                    }`}
            >
                <div className="mr-2">
                    {icon}
                </div>
                <div>
                    <p>{titulo}</p>
                </div>
            </div>
        </Link>
    }

    return (
        <>
            <TopBar showNav={showNav} setShowNav={setShowNav} />

            <Transition
                as={Fragment}
                show={showNav}
                enter="transform transition duration-[400ms]"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform duration-[400ms] transition ease-in-out"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
            >
                <SideBar showNav={showNav}>
                    {/* {item('Home', <HomeIcon className="h-5 w-5" />, '/admin', '/')} */}
                    {/* {{item('Clientes', <UserGroupIcon className="h-5 w-5" />, '/admin/clientes', '/clientes')} */}
                    {item('Eventos', <CalendarDaysIcon className="h-5 w-5" />, '/admin/eventos', '/eventos')}
                    {/* {item('Promotores', <UserGroupIcon className="h-5 w-5" />, '/admin/promotores', '')} */}
                    {item('Usuários', <UsersIcon className="h-5 w-5" />, '/admin/usuarios', '/usuarios')}
                    {/* {item('Vendas', <MdPaid className="h-5 w-5" />, '/admin/vendas', '/vendas')} */}
                    {/* {item('VipCards', <FaIdCard className="h-5 w-5" />, '/admin/vipcards', '/vipcards')} */}
                    {item('Relatórios', <TbChartInfographic className="h-5 w-5" />, '/admin/relatorios', '/relatorios')}
                    {item('Permissões', <AiOutlineFileProtect className="h-5 w-5" />, '/admin/permissoes', '/permissoes')}
                    {item('Empresas', <MdBusiness className="h-5 w-5" />, '/admin/empresa', '/empresa')}
                    {/* {item('Reservas', <CalendarDaysIcon className="h-5 w-5" />, '/admin/reservas', '/reservas')} */}
                    {item('Validar', <QrCodeIcon className="h-5 w-5" />, '/admin/validar', '/validar')}

                    {item('Sorteio Eventos', <GiLaurelsTrophy className="h-5 w-5" />, '/admin/sorteio', '/sorteio')}
                    {item('Sorteio Web', <GiLaurelsTrophy className="h-5 w-5" />, '/admin/sorteio-web', '/sorteio-web')}

                    {item('Ir para o Site', <RiEarthLine className="h-5 w-5" />, '/', '/')}

                </SideBar>

            </Transition>
            <main
                className={`conteudo-layout pt-8 transition-all duration-[400ms] ${showNav && !isMobile ? "pl-56" : ""
                    }`}
            >
                <div className="px-4 py-10 md:px-16 min-h-screen bg-gray-100">
                    {RoleGuard.canRole(RolesNames.ADMIN, session?.user.roles) && (
                        <>
                            {children}
                        </>
                    )}
                    
                </div>
            </main>
        </>
    )
}