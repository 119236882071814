import { Button } from "flowbite-react";
import Lottie from "lottie-react";
import { useRouter } from "next/router";
import { LayoutAdmin } from "../components/layouts/Layout";
import anime from '../public/404.json'

export default function Page404() {
    const router = useRouter()
    
    return (
        <>
            <div className=" flex justify-center items-center h-screen" style={{backgroundColor: '#ecf0f1'}}>
                <div className="grid justify-center" style={{ width: 400 }}>
                    <Lottie className=" xl:col-span-2 m-auto" animationData={anime} autoPlay={true} loop={true} />
                    <div className="flex justify-center py-10" style={{width: 400}}>
                        <Button color="gray" onClick={() => {router.push('/')}}>Página Inicial</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

Page404.layout = LayoutAdmin